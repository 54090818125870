<template>
	<div class="orderPay">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="支付流水号">
						<el-input v-model="form.payNo" placeholder="请输入支付流水号" clearable></el-input>
					</el-form-item>
					<el-form-item label="支付状态">
						<!-- <el-input v-model="form.status" placeholder="请输入支付状态" clearable></el-input> -->
						<el-select v-model="form.status" placeholder="请选择支付状态" clearable>
							<el-option label="支付成功" value="1"></el-option>
							<el-option label="支付失败" value="0"></el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'startOrderDate',end:'endOrderDate'}" />
                    </el-form-item>
					<!-- <el-form-item label="订单开始时间" prop="disinfectTime">
						<el-date-picker v-model="form.startOrderDate" type="datetime" placeholder="开始时间"
							label-width="10px" clearable format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="到" prop="disinfectTime">
						<el-date-picker v-model="form.endOrderDate" type="datetime" placeholder="结束时间" clearable
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item> -->
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
						<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="payNo" label="支付流水号" align="center"></el-table-column>
                    <el-table-column prop="tradeNo" label="第三方交易单号" align="center"></el-table-column>
					<el-table-column prop="payChannel" label="支付渠道" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="success" v-if="scope.row.payChannel==='1'">{{ '微信' }}</span>
							<span type="success" effect="dark" v-else-if="scope.row.payChannel==='2'">{{ '支付宝' }}
							</span>
							<span type="success" effect="dark" v-else-if="scope.row.payChannel==='3'">{{ '帐户余额' }}
							</span>
							<span type="success" effect="dark" v-else-if="scope.row.payChannel==='4'">{{ '二维码' }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="payReturn" label="订单来源" align="center"></el-table-column>

					<el-table-column prop="payType" label="支付类型" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="warning" v-if="scope.row.payType==='0'">{{ '预付' }}</span>
							<span type="success" effect="dark" v-else-if="scope.row.payType==='1'">{{ '完成支付' }}</span>
                            <span effect="dark" v-else-if="scope.row.payType === '2'">{{ "补充收款" }}</span>

						</template>
					</el-table-column>
					<el-table-column prop="payFee" label="支付费用" align="center"></el-table-column>
					<el-table-column prop="payTime" label="支付时间" align="center"></el-table-column>
					<el-table-column prop="status" label="支付状态" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="success" v-if="scope.row.status==='1'">{{ '支付成功' }}</span>
							<span type="warning" effect="dark" v-else>{{ '支付失败' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="备注" align="center"></el-table-column>
					<el-table-column label="详情" align="center">
						<template slot-scope="scope">
							<el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.pageNo" :page-sizes="[5, 10, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				<!-- 订单详情弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
					<span slot="title" class="dialog-Title">订单其他费用记录</span>
					<el-form :model="orderForm" inline ref="orderForm">
						<el-row>
							<el-form-item label="订单编号" prop="orderNo">
								<el-input v-model="orderForm.orderNo" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="支付渠道" prop="payChannel">
								<el-select v-model="orderForm.payChannel" placeholder="支付渠道" filterable clearable
									:disabled="isdisable">
									<el-option label="微信" value="1"></el-option>
									<el-option label="支付宝" value="2"></el-option>
									<el-option label="帐户余额" value="3"></el-option>
									<el-option label="二维码" value="4"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="支付类型" prop="payType">
								<el-select v-model="orderForm.payType" placeholder="支付类型" filterable clearable
									:disabled="isdisable">
									<el-option label="预付" value="0"></el-option>
									<el-option label="完成支付" value="1"></el-option>
                                    <el-option label="补充收款" value="2"></el-option>

								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="支付费用" prop="payFee">
								<el-input v-model="orderForm.payFee" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="支付时间" prop="payTime">
								<el-input v-model="orderForm.payTime" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="支付状态" prop="status">
								<el-select v-model="orderForm.status" placeholder="支付失败" filterable clearable
									:disabled="isdisable">
									<el-option label="支付成功" value="1"></el-option>
									<el-option label="支付失败" value="0"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="备注" prop="remark">
								<el-input v-model="orderForm.remark" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
					</div>
				</el-dialog>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'orderPay',
  components: {
    WTimer
  },
  data () {
    return {
      List: [], // 表格数据源
      form: {
        orderNo: '',
        payNo: '',
        status: '',
        startOrderDate: '',
        endOrderDate: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderFormRules: {
        dispatchItem: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }]
      }, // 编辑资料表单验证规则
      isdisable: true
    }
  },
  created () {
    this.getOrderList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getOrderList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getOrderList()
    },
    // 获取订单支付记录
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrderPay/getTInfoOrderPayList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        ...this.form,
        starOrderDate: this.form.startOrderDate,
        headers: {
          AUTH_TYPE: 'orderPay'
        }
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = +res.data.total
      console.log(res)
    },
    // 点击搜索按钮触发
    async search () {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrderPay/getTInfoOrderPayList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        status: this.form.status,
        starOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        payNo: this.form.payNo,
        headers: {
          AUTH_TYPE: 'orderPay'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data.records
      this.total = +res.data.total
      // // 判断有输入账号
      // if (this.form.orderNo === '') {
      // 	this.getOrderList()
      // } else {

      // }
    },
    // 点击查看详情按钮触发
    look (v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    reset () { // 重置
      this.form.orderNo = ''
      this.form.payNo = ''
      this.form.status = ''
      this.form.startOrderDate = ''
      this.form.endOrderDate = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    }
  }

}
</script>
<style lang="less" scoped>
	.orderPay {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					.dialog-Title {
						color: #0097fe;
						font-size: 20px;
						font-weight: 700;
					}

					/deep/ .el-form {
						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}

						.el-row {
							display: flex;

							.el-form-item {
								flex: 1;
								display: flex;

								.el-form-item__content {
									flex: 1;

									.el-input {
										width: 100%;

										.el-input__inner {
											width: 100%;
										}
									}

									.el-select {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
